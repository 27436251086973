import React from "react";
import ContainerTriangle from "-!svg-react-loader!./triangle-bottom.svg";
import "./Container.scss";

export default function Container(props) {
  return (
    <div
      className={
        "container " +
        (props.layout ? "container--" + props.layout : "") +
        " " +
        (props.type ? "container--" + props.type : "") +
        " " +
        (props.name ? props.name + "__container" : "") +
        " " +
        (props.className ? props.className : "")
      }
    >
      {props.type === "background-climb" ? (
        <ContainerTriangle className="container__decor" />
      ) : props.type === "background-decor" ? (
        <ContainerTriangle className="container__decor" />
      ) : (
        ""
      )}
      <div
        className={
          "container__pad " + (props.name ? props.name + "__container-pad" : "")
        }
      >
        <div
          className={
            "container__content " +
            (props.name ? props.name + "__container-content" : "")
          }
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
