import React, { useState } from "react";
import Logo from "../Logo/Logo";
import NavButton from "./NavButton";
import { Link } from "gatsby";
import ContactIcon from "-!svg-react-loader!./icon-contact.svg";
import NavMenu from "./NavMenu";
import HeaderTriangle from "-!svg-react-loader!./triangle-top.svg";
import "./Header.scss";

// markup
export default function Header() {
  const [open, setopen] = useState(false);

  return (
    <header className="header">
      <div className="header__bar">
        <h1 className="header__logo">
          <Link className="header__logo-link" to="/">
            <Logo className="header__logo-icon" />
            <span className="header__logo-text">Yvette Russell</span>
          </Link>
        </h1>
        <NavButton
          onClick={() => setopen(!open)}
          open={open}
        />
        <Link
          className="header__bar-link header__bar-contact mobile-only"
          to="/contact"
        >
          <ContactIcon />
        </Link>
      </div>
      <NavMenu open={open} />
      <HeaderTriangle className="header__decor" />
    </header>
  );
}
