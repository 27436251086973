import React, { useState } from "react";
import NavIcon from "-!svg-react-loader!./icon-menu.svg";
import NavCloseIcon from "-!svg-react-loader!./icon-close.svg";
import "./NavButton.scss";

// markup
export default function NavButton(props) {
  const [open] = useState(false);

  return (
    <button
      {...props}
      className={`nav-button mobile-only ${
        props.open === open ? "nav-button--is-closed" : "nav-button--is-open"
      }`}
    >
      <NavIcon className="nav-button__menu icon--active" />
      <NavCloseIcon className="nav-button__close" />
    </button>
  );
}
