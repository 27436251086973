import React from "react";
import Container from "../Container/Container";
import "./Hero.scss";

export default function Hero(props) {
	return (
		<div
			className={
				"hero u-pad-top-xl u-pad-btm-xl " +
				(props.type ? "hero--" + props.type : "")
			}
		>
			<Container name="hero">{props.children}</Container>
		</div>
	);
}
