import React, { useState } from "react";
import { Link } from "gatsby";
import Button from "../Button/Button";
import "./NavMenu.scss";

// markup
export default function NavMenu(props) {
  const [open] = useState(false);

  return (
    <nav
      setopen={props.setopen ? props.setopen : 'boop'}
      className={`nav ${
        props.open === open ? "nav--is-closed" : "nav--is-open"
      }`}
    >
      <ul className="nav__list">
        <li className="nav__item">
          <Link
            to="/about"
            className="nav__link"
            activeClassName="nav--active"
          >
            <span>About</span>
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/books"
            className="nav__link"
            activeClassName="nav--active"
          >
            <span>Books</span>
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/contact"
            className="nav__link"
            activeClassName="nav--active"
          >
            <span>Contact</span>
          </Link>
        </li>
        <li className="nav__item">
          <Link
            to="/links"
            className="nav__link"
            target="_blank"
            rel="noreferrer"
            activeClassName="nav--active"
          >
            <span>Links</span>
          </Link>
        </li>
        <li className="nav__item nav--patreon">
          <Button
            url="https://www.wattpad.com/yvetterussell"
            target="_blank"
            type="primary"
          >
            Wattpad
          </Button>
        </li>
      </ul>
    </nav>
  );
}
