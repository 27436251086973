import React from "react";
import Button from "../Button/Button";
import "./PageHeading.scss";

export default function PageHeading(props) {
  return (
    <div className="page-heading">
      {React.Children.map(
        props.children,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child, {
            className:
              "page-heading__" +
              (child.type === "h2"
                ? "title"
                : child.type === "p"
                ? "tagline"
                : child.type === Button
                ? "cta"
                : "child"),
          })
      )}
    </div>
  );
}
