import * as React from "react";
import "./Button.scss";

// markup
export default function Button(props) {
  return (
    <a
      role="button"
      href={props.url}
      target={props.target}
      className={
        "button " +
        (props.type === "primary"
          ? "button--primary "
          : props.type === "christmas"
          ? "button--christmas button--sparkle "
          : "button--secondary ") + 
        props.className
      }
    > {props.type === "christmas" ? (
        <span className="button__inner">{props.children}</span>
      ) : (
        <span>{props.children}</span>
      )}
    </a>
  );
}
