import * as React from "react";
import Header from "../components/Header/Header";
import Hero from "../components/Heading/Hero";
import PageHeading from "../components/Heading/PageHeading";
import Button from "../components/Button/Button";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Header />
      <Hero>
        <PageHeading>
          <h2>
            <strong>404:</strong> Lost in the Woods
          </h2>
          <p>Lost your way? Don't worry. You can always...</p>
          <Button url="/" type="primary">
            Go Home
          </Button>
        </PageHeading>
      </Hero>
    </main>
  );
};

export default NotFoundPage;
